import { Navigate, useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { paths } from 'src/routes/paths';
import {
  ENVIRONMENT,
  PATH_ADMIN_AFTER_LOGIN,
  PATH_AGENT_AFTER_LOGIN,
  PATH_AUDITOR_AFTER_LOGIN,
  PATH_CUSTOMER_AFTER_LOGIN,
} from 'src/config-global';
import { CONSTANT_VARIABLES } from 'src/constants/constants-variables';
import { isLoggedIn, isRoleAdmin, isUserAgent, isUserAuditor } from 'src/utils/check-user-is-admin';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { publicRoutes } from './public';
import { adminRoutes } from './admin';
import { customerRoutes } from './customer';
import { downloadRoutes } from './downloads';
import { agentRoutes } from './agent';
import { auditorRoutes } from './auditor';

// ----------------------------------------------------------------------

export default function Router() {
  const modUseRoutes =
    ENVIRONMENT === CONSTANT_VARIABLES.PRODUCTION ? wrapUseRoutes(useRoutes) : useRoutes;

  let indexPath = paths.login;

  if (isLoggedIn()) {
    if (isRoleAdmin()) {
      indexPath = PATH_ADMIN_AFTER_LOGIN;
    } else if (isUserAgent()) {
      indexPath = PATH_AGENT_AFTER_LOGIN;
    } else if (isUserAuditor()) {
      indexPath = PATH_AUDITOR_AFTER_LOGIN;
    } else {
      indexPath = PATH_CUSTOMER_AFTER_LOGIN;
    }
  }

  return modUseRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={indexPath} replace />,
    },

    // ----------------------------------------------------------------------

    // public routes
    ...publicRoutes,

    // Auth routes
    ...authRoutes,

    // Customer routes
    ...customerRoutes,

    // Admin routes
    ...adminRoutes,

    // Main routes
    ...mainRoutes,

    // downloads
    ...downloadRoutes,

    // Agent routes
    ...agentRoutes,

    // Auditor routes
    ...auditorRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
