import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

import AdminOrAgentGuard from 'src/auth/guard/admin-agent-guard';

import AdminAgentOrCusotmerGuard from 'src/auth/guard/admin-agent-customer-guard';

import { paths } from '../paths';

// ----------------------------------------------------------------------
const CatalogShopPage = lazy(() => import('src/pages/customer/catalog'));

// client order
const ClientOrdersPage = lazy(() => import('src/pages/admin/client-order/list'));
const ClientOrderCreatePage = lazy(() => import('src/pages/admin/client-order/new'));
const ClientOrderEditPage = lazy(() => import('src/pages/admin/client-order/edit'));
const ClientOrderDetailsPage = lazy(() => import('src/pages/admin/client-order/details'));

// Agents
const ClientReferralsListPage = lazy(() => import('src/pages/admin/client-referral/list'));

// clients
const ClientListPage = lazy(() => import('src/pages/admin/client/list'));
// ----------------------------------------------------------------------
export const agentRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.customer.catalog,
        element: (
          <AdminAgentOrCusotmerGuard>
            <CatalogShopPage />
          </AdminAgentOrCusotmerGuard>
        ),
      },
      {
        path: paths.admin.orderTypes.clientOrders.root,
        element: (
          <AdminOrAgentGuard>
            <ClientOrdersPage />
          </AdminOrAgentGuard>
        ),
      },
      {
        path: paths.admin.orderTypes.clientOrders.new,
        element: (
          <AdminOrAgentGuard>
            <ClientOrderCreatePage />
          </AdminOrAgentGuard>
        ),
      },
      {
        path: paths.admin.orderTypes.clientOrders.edit(':order_code'),
        element: (
          <AdminOrAgentGuard>
            <ClientOrderEditPage />
          </AdminOrAgentGuard>
        ),
      },
      {
        path: paths.admin.orderTypes.clientOrders.details(':order_code'),
        element: (
          <AdminOrAgentGuard>
            <ClientOrderDetailsPage />
          </AdminOrAgentGuard>
        ),
      },
      {
        path: paths.admin.clientReferrals.root,
        element: (
          <AdminOrAgentGuard>
            <ClientReferralsListPage />
          </AdminOrAgentGuard>
        ),
      },
      {
        path: paths.admin.clients.root,
        element: (
          <AdminOrAgentGuard>
            <ClientListPage />
          </AdminOrAgentGuard>
        ),
      },
    ],
  },
];
