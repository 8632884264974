const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/',
  ADMIN: '/admin',
  ORDER_TYPES: '/order-types',
  PRODUCTS: '/products',
  REORDER_PRODUCTS: '/reorder',
  PRODUCER_ORDERS: '/producer_order',
};

// ----------------------------------------------------------------------

export const paths = {
  root: '/',
  // comingSoon: '/coming-soon',
  // maintenance: '/maintenance',
  // page500: '/500',
  page403: '/403',
  page404: '/404',
  cookies: '#',

  // dummy paths for avoid build fail
  // faqs: 'faqs/',
  // minimalUI: '/minimalUI',
  // contact: '/contact',
  // changelog: '/changelog',
  // about: '/about',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  // AUTH
  auth: {
    root: ROOTS.AUTH,
    verify: `${ROOTS.AUTH}/verify`,
    // jwt: {
    //   login: `${ROOTS.AUTH}/login`,
    // },
    // firebase: {
    //   login: `${ROOTS.AUTH}/login`,
    //   register: `${ROOTS.AUTH}/register`,
    //   // forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    // },
    login: `${ROOTS.AUTH}/login`,
  },
  // CUSTOMER
  customer: {
    catalog: `/catalog`,
    checkout: `/checkout`,
    paymentStatus: `/payment-status`,
    orders: { root: `/orders`, details: (order_code: string) => `/orders/${order_code}` },
    producers: '/producers',
  },
  public: {
    downloadProducts: (token: string) => `/downloads/clientPdf/${token}`,
    generic: '/downloads/listino-aggiornato.pdf',
  },
  // ADMIN
  admin: {
    root: ROOTS.ADMIN,
    orderTypes: {
      producerOrders: {
        root: `${ROOTS.ADMIN}/producer-orders`,
        new: `${ROOTS.ADMIN}/producer-orders/new`,
        edit: (order_code: string) => `${ROOTS.ADMIN}/producer-orders/${order_code}/edit`,
        details: (order_code: string) => `${ROOTS.ADMIN}/producer-orders/${order_code}`,
      },
      clientOrders: {
        root: `${ROOTS.ADMIN}/client-orders`,
        new: `${ROOTS.ADMIN}/client-orders/new`,
        edit: (order_code: string) => `${ROOTS.ADMIN}/client-orders/${order_code}/edit`,
        details: (order_code: string) => `${ROOTS.ADMIN}/client-orders/${order_code}`,
      },
    },
    dashboard: {
      overview: `${ROOTS.ADMIN}/dashboard/overview`,
      overlays: {
        root: `${ROOTS.ADMIN}/overlay`,
        new: `${ROOTS.ADMIN}/overlay/new`,
        edit: (overlayId: string) => `${ROOTS.ADMIN}/overlay/${overlayId}/edit`,
        details: (overlayId: string) => `${ROOTS.ADMIN}/overlay/${overlayId}`,
      },
    },
    clients: {
      root: `${ROOTS.ADMIN}/clients`,
      new: `${ROOTS.ADMIN}/clients/new`,
      edit: (slug: string) => `${ROOTS.ADMIN}/clients/${slug}/edit`,
      details: (slug: string) => `${ROOTS.ADMIN}/clients/${slug}`,
    },
    users: {
      root: `${ROOTS.ADMIN}/users`,
      new: `${ROOTS.ADMIN}/users/new`,
      edit: (slug: string) => `${ROOTS.ADMIN}/users/${slug}/edit`,
      details: (slug: string) => `${ROOTS.ADMIN}/users/${slug}`,
    },
    reservations: {
      root: `${ROOTS.ADMIN}/reservations`,
      new: `${ROOTS.ADMIN}/reservations/new`,
      edit: (client_slug: string, sku: string) =>
        `${ROOTS.ADMIN}/reservations/${client_slug}/product/${sku}/edit`,
    },
    clientReferrals: {
      root: `${ROOTS.ADMIN}/client-referrals`,
      new: `${ROOTS.ADMIN}/client-referrals/new`,
      edit: (slug: string) => `${ROOTS.ADMIN}/client-referrals/${slug}/edit`,
      details: (slug: string) => `${ROOTS.ADMIN}/client-referrals/${slug}`,
    },
    tastings: {
      root: `${ROOTS.ADMIN}/tastings`,
      new: `${ROOTS.ADMIN}/tastings/new`,
      edit: (slug: string) => `${ROOTS.ADMIN}/tastings/${slug}/edit`,
      details: (slug: string) => `${ROOTS.ADMIN}/tastings/${slug}`,
    },
    products: {
      root: `${ROOTS.ADMIN}${ROOTS.PRODUCTS}`,
      new: `${ROOTS.ADMIN}${ROOTS.PRODUCTS}/new`,
      edit: (sku: string) => `${ROOTS.ADMIN}${ROOTS.PRODUCTS}/${sku}/edit`,
      details: (sku: string) => `${ROOTS.ADMIN}${ROOTS.PRODUCTS}/${sku}`,
    },
    reorderProducts: {
      root: `${ROOTS.ADMIN}${ROOTS.REORDER_PRODUCTS}`,
    },
    producers: {
      root: `${ROOTS.ADMIN}/producers`,
      new: `${ROOTS.ADMIN}/producers/new`,
      edit: (slug: string) => `${ROOTS.ADMIN}/producers/${slug}/edit`,
      details: (slug: string) => `${ROOTS.ADMIN}/producers/${slug}`,
    },
    wineTypes: {
      root: `${ROOTS.ADMIN}/wine-types`,
      new: `${ROOTS.ADMIN}/wine-types/new`,
      edit: (slug: string) => `${ROOTS.ADMIN}/wine-types/${slug}/edit`,
      details: (slug: string) => `${ROOTS.ADMIN}/wine-types/${slug}`,
    },
    statisticTypes: {
      root: `${ROOTS.ADMIN}`,
      sale_stats: `${ROOTS.ADMIN}/stats`,
      annual_stats: `${ROOTS.ADMIN}/yearly_stats`,
      product_stats: `${ROOTS.ADMIN}/product_stats`,
      profitability: `${ROOTS.ADMIN}/profitability`,
      bottles_flow: `${ROOTS.ADMIN}/bottles-flow`,
    },
    producer_orders: {
      details: (order_code: string) => `${ROOTS.ADMIN}${ROOTS.PRODUCER_ORDERS}/${order_code}`,
    },
  },
};
