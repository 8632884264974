// import uniq from 'lodash/uniq';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSWRConfig } from 'swr';
import { useSnackbar } from 'notistack';
// hooks
import {
  addToCart,
  deleteFromCart,
  useGetCartProducts,
  useGetCurrentOrder,
} from 'src/api/customer';
// import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// _mock
// types
// import { IAddressItem } from 'src/types/address';
// import { ICheckoutItem } from 'src/types/checkout';
import { ICartProduct, IAddToCart, ICurrentCartOrder } from 'src/types';
import { endpoints } from 'src/utils/axios';
import { AUTH_STORAGE_KEYS } from 'src/utils/storage-available';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { CHECKOUT_TABS, PRODUCT_CHECKOUT_STEPS } from '../common/constant';
//
import { CheckoutContext } from './checkout-context';

// ----------------------------------------------------------------------

// const STORAGE_KEY = 'checkout';

const initialState = {
  activeStep: CHECKOUT_TABS.ORDER,
  cartProducts: [],
  subTotal: 0,
  total: 0,
  discount: 0,
  shipping: 0,
  billing: null,
  totalItems: 0,
  discountPercentage: 0,
};

type Props = {
  children: React.ReactNode;
};

export function CheckoutProvider({ children }: Props) {
  const router = useRouter();
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState(initialState);
  const [confirmedOrder, setConfirmedOrder] = useState<ICurrentCartOrder | null>(null);
  const [placedOrderCode, setPlacedOrderCode] = useState<string>('');

  const updateState = useCallback((newState: any) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  const { state: storage, update: updateSelectedAgentClientId } = useLocalStorage(
    AUTH_STORAGE_KEYS.selectedAgentClientId,
    {},
  );
  const { selectedAgentClientId } = storage;

  const { data: cart_products } = useGetCartProducts({
    clientId: selectedAgentClientId,
  });
  const { data: current_order } = useGetCurrentOrder({
    isCartEmpty: !cart_products || (cart_products && Object.keys(cart_products).length === 0),
    clientId: selectedAgentClientId,
  });

  const refetchCart = useCallback(() => {
    mutate([
      endpoints.customer.cart_products.root,
      { params: { clientId: selectedAgentClientId } },
    ]);
    mutate([
      endpoints.customer.client_orders.current_order,
      { params: { clientId: selectedAgentClientId } },
    ]);
  }, [mutate, selectedAgentClientId]);

  const onAddToCart = useCallback(
    async (data: IAddToCart, callback?: () => void) => {
      try {
        await addToCart(data);
        refetchCart();
        if (callback) {
          callback();
        }
      } catch (error) {
        if (error?.response?.data) {
          enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        }
        if (callback) {
          callback();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAgentClientId, refetchCart],
  );

  const onDeleteFromCart = useCallback(
    async (sku: string) => {
      try {
        await deleteFromCart(sku, selectedAgentClientId);
        refetchCart();
      } catch (error) {
        if (error?.response?.data) {
          enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        }
      }
    },
    [selectedAgentClientId, refetchCart, enqueueSnackbar],
  );

  const getCartItem = useCallback(
    (sku: string) => (cart_products ? cart_products[sku] : undefined),
    [cart_products],
  );

  const onBackStep = useCallback(() => {
    updateState({ activeStep: state.activeStep - 1 });
  }, [updateState, state.activeStep]);

  const onNextStep = useCallback(() => {
    updateState({ activeStep: state.activeStep + 1 });
  }, [updateState, state.activeStep]);

  const onGotoStep = useCallback(
    (step: number) => {
      updateState({ activeStep: step });
    },
    [updateState],
  );

  const onApplyDiscount = useCallback((discount: number) => {
    // update('discount', discount);
  }, []);

  // const onApplyShipping = useCallback(
  //   (shipping: number) => {
  //     update('shipping', shipping);
  //   },
  //   [update],
  // );

  const completed = state.activeStep === PRODUCT_CHECKOUT_STEPS.length;

  // Reset
  const onReset = useCallback(() => {
    if (completed) {
      refetchCart();
      updateState({ activeStep: CHECKOUT_TABS.ORDER });
      router.replace(paths.customer.catalog);
    }
  }, [completed, refetchCart, updateState, router]);

  useEffect(() => {
    if (!cart_products) return;

    const { total_end_price, subTotal, modifiedCart } = Object.values(cart_products).reduce<{
      total_end_price: number;
      subTotal: number;
      modifiedCart: ICartProduct[];
    }>(
      (accumulator, cartProduct) => {
        accumulator.total_end_price += cartProduct.total_end_price;
        accumulator.subTotal += cartProduct.base_price * cartProduct.quantity;

        accumulator.modifiedCart.push(cartProduct);

        return accumulator;
      },
      { total_end_price: 0, subTotal: 0, modifiedCart: [] },
    );

    const discountValue = total_end_price - subTotal;

    updateState({
      cartProducts: modifiedCart,
      total: total_end_price,
      totalItems: modifiedCart.length || 0,
      subTotal,
      discount: discountValue,
      discountPercentage: (discountValue / subTotal) * 100 || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart_products]);
  const memoizedValue = useMemo(
    () => ({
      ...state,

      completed,
      updateState,
      onReset,

      //
      //
      onAddToCart,
      onDeleteFromCart,
      getCartItem,
      refetchCart,
      // onDeleteCart,
      //
      // onIncreaseQuantity,
      // onDecreaseQuantity,
      //
      // onCreateBilling,
      onApplyDiscount,
      // onApplyShipping,
      //
      onBackStep,
      onNextStep,
      onGotoStep,
      //
      // onReset,
      current_order,
      confirmedOrder,
      setConfirmedOrder,
      placedOrderCode,
      setPlacedOrderCode,
      selectedAgentClientId,
      updateSelectedAgentClientId,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      completed,
      onAddToCart,
      onDeleteFromCart,
      getCartItem,
      refetchCart,
      //
      //
      onApplyDiscount,
      // onApplyShipping,
      onBackStep,
      // onCreateBilling,
      // onDecreaseQuantity,
      // onDeleteCart,
      onGotoStep,
      // onIncreaseQuantity,
      onNextStep,
      // onReset,
      state,
      updateState,
      onReset,
      current_order,
      confirmedOrder,
      setConfirmedOrder,
      placedOrderCode,
      setPlacedOrderCode,
      selectedAgentClientId,
      updateSelectedAgentClientId,
    ],
  );

  return <CheckoutContext.Provider value={memoizedValue}>{children}</CheckoutContext.Provider>;
}
