import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { ENVIRONMENT } from 'src/config-global';
import { CONSTANT_VARIABLES } from 'src/constants/constants-variables';
import { isRoleAdmin, isUserAgent, isUserAuditor } from 'src/utils/check-user-is-admin';

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} />;

const ICONS = {
  catalog: icon('ic:baseline-menu-book'),
  orders: icon('ic:outline-shopping-cart'),
  producers: icon('fluent-emoji-high-contrast:grapes'),
  admin: icon('mdi-archive-settings'),
  userTypes: icon('ic:outline-supervised-user-circle'),
  orderTypes: icon('ic:baseline-card-giftcard'),
  adminOrders: icon('mdi-archive-settings'),
  adminProducts: icon('mdi-format-list-bulleted-square'),
  statistics: icon('mdi:chart-line-variant'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const isDevelopment = ENVIRONMENT === CONSTANT_VARIABLES.DEVELOP;
  const isProductionOrStage = new Set([
    CONSTANT_VARIABLES.STAGE,
    CONSTANT_VARIABLES.PRODUCTION,
  ]).has(ENVIRONMENT);
  const isAgent = isUserAgent();
  const isAdmin = isRoleAdmin();
  const isAuditor = isUserAuditor();

  // Hide from production -----------------------------
  // Note: Temp have enabled stats pages for production
  const isStagingOrDevMode = useMemo(
    () =>
      ENVIRONMENT === CONSTANT_VARIABLES.STAGE ||
      ENVIRONMENT === CONSTANT_VARIABLES.DEVELOP ||
      ENVIRONMENT === CONSTANT_VARIABLES.PRODUCTION
        ? [
            {
              title: t('statistics'),
              path: [
                paths.admin.statisticTypes.sale_stats,
                paths.admin.statisticTypes.annual_stats,
                paths.admin.statisticTypes.product_stats,
                paths.admin.statisticTypes.profitability,
                paths.admin.statisticTypes.bottles_flow,
              ],
              icon: ICONS.statistics,
              children: [
                { title: t('sales_statistics'), path: paths.admin.statisticTypes.sale_stats },
                { title: t('annual_statistics'), path: paths.admin.statisticTypes.annual_stats },
                { title: t('product_statistics'), path: paths.admin.statisticTypes.product_stats },
                { title: t('profitability'), path: paths.admin.statisticTypes.profitability },
                { title: t('bottles_flow'), path: paths.admin.statisticTypes.bottles_flow },
              ],
            },
          ]
        : [],
    [t],
  );

  const customerItems = useMemo(
    () => [
      {
        title: t('catalog'),
        path: paths.customer.catalog,
        icon: ICONS.catalog,
        disclaimerButton: !isAdmin,
      },
      {
        title: t('producers'),
        path: paths.customer.producers,
        icon: ICONS.producers,
      },
      {
        title: t('order_list'),
        path: paths.customer.orders.root,
        icon: ICONS.orders,
      },
    ],
    [isAdmin, t],
  );

  const commonAdminItems = useMemo(
    () => [
      {
        title: t('dashboard'),
        path: [paths.admin.dashboard.overview, paths.admin.dashboard.overlays.root],
        icon: ICONS.admin,
        children: [
          { title: t('overview'), path: paths.admin.dashboard.overview },
          { title: t('overlays'), path: paths.admin.dashboard.overlays.root },
        ],
      },
      {
        title: t('clients'),
        path: [
          paths.admin.users.root,
          paths.admin.clients.root,
          paths.admin.reservations.root,
          paths.admin.tastings.root,
          paths.admin.clientReferrals.root,
        ],
        icon: ICONS.userTypes,
        children: [
          { title: t('users'), path: paths.admin.users.root },
          { title: t('clients'), path: paths.admin.clients.root },
          { title: t('reservations'), path: paths.admin.reservations.root },
          { title: t('tastings'), path: paths.admin.tastings.root },
          { title: t('agent'), path: paths.admin.clientReferrals.root },
        ],
      },
      {
        title: t('orders'),
        path: [
          paths.admin.orderTypes.clientOrders.root,
          paths.admin.orderTypes.producerOrders.root,
        ],
        icon: ICONS.admin,
        children: [
          { title: t('clients'), path: paths.admin.orderTypes.clientOrders.root },
          { title: t('providers'), path: paths.admin.orderTypes.producerOrders.root },
        ],
      },
      {
        title: t('products'),
        path: [
          paths.admin.products.root,
          paths.admin.reorderProducts.root,
          paths.admin.producers.root,
          paths.admin.wineTypes.root,
        ],
        icon: ICONS.adminProducts,
        children: [
          { title: t('all_the_products'), path: paths.admin.products.root },
          { title: t('reorder-products'), path: paths.admin.reorderProducts.root },
          { title: t('producers'), path: paths.admin.producers.root },
          { title: t('wine_types'), path: paths.admin.wineTypes.root },
        ],
      },
      ...isStagingOrDevMode,
    ],
    [t, isStagingOrDevMode],
  );

  const commonAgentItems = useMemo(
    () => [
      {
        title: t('catalog'),
        path: paths.customer.catalog,
        icon: ICONS.catalog,
      },
      {
        title: t('clients'),
        path: [paths.admin.clients.root, paths.admin.clients.root],
        icon: ICONS.userTypes,
        children: [
          { title: t('clients'), path: paths.admin.clients.root },
          { title: t('agent'), path: paths.admin.clientReferrals.root },
        ],
      },
      {
        title: t('orders'),
        path: [paths.admin.orderTypes.clientOrders.root],
        icon: ICONS.admin,

        children: [{ title: t('clients'), path: paths.admin.orderTypes.clientOrders.root }],
      },
    ],
    [t],
  );

  const commonAuditorItems = useMemo(
    () => [
      {
        title: t('orders'),
        path: [paths.admin.orderTypes.producerOrders.root],
        icon: ICONS.admin,
        children: [{ title: t('providers'), path: paths.admin.orderTypes.producerOrders.root }],
      },
    ],
    [t],
  );
  const developmentAdminItems = useMemo(() => commonAdminItems, [commonAdminItems]);

  const prodORStageAdminItems = useMemo(() => [...commonAdminItems], [commonAdminItems]);

  const data = useMemo(() => {
    if (isProductionOrStage && isAdmin) {
      return [
        { subheader: t('overview'), items: customerItems },
        { subheader: t('admin'), items: prodORStageAdminItems },
      ];
    }
    if (isProductionOrStage && !isAdmin && !isAgent && !isAuditor) {
      return [{ subheader: t('overview'), items: customerItems }];
    }
    if (isProductionOrStage && isAgent) {
      return [{ subheader: t('overview'), items: commonAgentItems }];
    }
    if (isDevelopment && isAgent) {
      return [{ subheader: t('overview'), items: commonAgentItems }];
    }
    if (isProductionOrStage && isAuditor) {
      return [{ subheader: t('overview'), items: commonAuditorItems }];
    }
    if (isDevelopment && isAuditor) {
      return [{ subheader: t('overview'), items: commonAuditorItems }];
    }
    if (isDevelopment && isAdmin) {
      return [
        { subheader: t('overview'), items: customerItems },
        { subheader: t('admin'), items: developmentAdminItems },
      ];
    }
    return [{ subheader: t('overview'), items: customerItems }];
  }, [
    t,
    isAdmin,
    isAgent,
    isAuditor,
    isDevelopment,
    isProductionOrStage,
    customerItems,
    developmentAdminItems,
    prodORStageAdminItems,
    commonAgentItems,
    commonAuditorItems,
  ]);

  return data;
}
