import { useMemo, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// @mui
import { Grid, Stack, Typography, IconButton, Divider } from '@mui/material';
import Iconify from 'src/components/iconify';
import Image from 'src/components/image';
import { useLocales } from 'src/locales';
import { useCheckoutContext } from 'src/sections/customer/checkout/context';
// import TextMaxLine from 'src/components/text-max-line/text-max-line';
// types;
import { fCurrency } from 'src/utils/format-number';
import IncrementerButton from 'src/components/incrementer-button';
import { quantityStepper, maxOrderable, handleQuantity } from 'src/utils/cart';
import { localStorageGetItem, AUTH_STORAGE_KEYS } from 'src/utils/storage-available';
import { IAddToCart, ICartProduct } from 'src/types';
import wineThumbFallback from 'src/assets/images/defaultBottleLogo.png';
// ----------------------------------------------------------------------

type Props = {
  cartProduct: ICartProduct;
};

export default function CartProductItem({ cartProduct }: Props) {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const checkout = useCheckoutContext();
  const [quantity, setQuantity] = useState<number>(cartProduct?.quantity || 0);
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);

  const selectedAgentClientId = JSON.parse(
    localStorageGetItem(AUTH_STORAGE_KEYS.selectedAgentClientId) || '{}',
  );

  useEffect(() => {
    if (cartProduct?.quantity !== quantity) {
      // @ts-ignore
      setQuantity(cartProduct?.quantity || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartProduct?.quantity]);

  const user = JSON.parse(localStorageGetItem(AUTH_STORAGE_KEYS.user) || '{}');

  const { product } = cartProduct;

  const { available_quantity = 0 } = product;

  const step = useMemo(
    () =>
      quantityStepper({
        wine_type: product?.wine_type,
        price: Number(product?.price) || 0,
        multiplesThreshold: user?.multiplesThreshold || 0,
      }),
    [product, user],
  );

  const maxQty = useMemo(
    () =>
      maxOrderable({
        totalQuantity: (product?.available_quantity || 0) + (cartProduct?.quantity || 0),
        product,
      }),
    [product, cartProduct],
  );

  const qty = useMemo(() => Math.max(cartProduct?.quantity || 0, 0), [cartProduct?.quantity]);

  const handleAddToCart = async (data: IAddToCart) => {
    try {
      setIsUpdatingCart(true);
      await checkout.onAddToCart(data);
      setIsUpdatingCart(false);
    } catch (err) {
      setIsUpdatingCart(false);
    }
  };

  const handleDeleteFromCart = async () => {
    try {
      setIsUpdatingCart(true);
      await checkout.onDeleteFromCart(product.sku, selectedAgentClientId);
      setIsUpdatingCart(false);
    } catch (err) {
      setIsUpdatingCart(false);
    }
  };

  const availableCount = useMemo(() => {
    if (cartProduct) {
      return cartProduct?.product?.available_quantity || 0;
    }
    return available_quantity - quantity < 0 ? 0 : available_quantity - quantity;
  }, [cartProduct, available_quantity, quantity]);

  return (
    <Stack margin={2} gap={2} display="flex" alignItems="center" sx={{ position: 'relative' }}>
      <IconButton
        sx={{ color: 'error.main', position: 'absolute', top: 0, right: -10 }}
        onClick={handleDeleteFromCart}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
      <Grid container p={1}>
        <Grid item xs={3} container alignItems="center">
          <Image
            src={
              typeof product?.image === 'string' || typeof product?.image === 'object'
                ? ((product?.image?.url || product?.image || wineThumbFallback) as string)
                : ''
            }
          />
        </Grid>
        <Grid item xs={9}>
          <Stack gap={0.7}>
            <Typography variant="subtitle2">
              {`${product?.name || ''} ${product?.vintage || ''}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {product.producer_name}
            </Typography>

            <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
              <Stack spacing={1}>
                <IncrementerButton
                  name="quantity"
                  quantity={quantity}
                  disabledDecrease={isUpdatingCart || quantity === 0}
                  disabledIncrease={isUpdatingCart || quantity === maxQty}
                  onIncrease={() => {
                    const calcQty = handleQuantity({
                      maxQty,
                      newQuantity: qty + step,
                      step,
                    });

                    setQuantity(calcQty);

                    handleAddToCart({
                      clientId: selectedAgentClientId,
                      sku: product.sku,
                      quantity: calcQty,
                    });

                    if (maxQty === calcQty) {
                      enqueueSnackbar(t('max_bottle_added_alert_msg'), { variant: 'warning' });
                    }
                  }}
                  onDecrease={() => {
                    const calcQty = handleQuantity({
                      maxQty,
                      newQuantity: Math.max(qty - step, 0),
                      step,
                    });

                    setQuantity(calcQty);

                    if (!calcQty) {
                      handleDeleteFromCart();
                    } else {
                      handleAddToCart({
                        clientId: selectedAgentClientId,
                        sku: product.sku,
                        quantity: calcQty,
                      });
                    }
                  }}
                />

                <Typography variant="caption" component="div" sx={{}}>
                  {t('available')}: {availableCount}
                </Typography>
              </Stack>
              <Typography variant="subtitle2">
                {fCurrency(cartProduct?.total_end_price || 0)}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ width: '100%' }} />
    </Stack>
  );
}
