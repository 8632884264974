import {
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  sub,
} from 'date-fns';

import img_bianco from 'src/assets/images/wine-types/bianco.png';
import img_birra from 'src/assets/images/wine-types/birra.png';
import img_distillati from 'src/assets/images/wine-types/distillati.png';
import img_analcolico from 'src/assets/images/wine-types/analcolico.png';
import img_macerato from 'src/assets/images/wine-types/macerato.png';
import img_metodo_classico from 'src/assets/images/wine-types/metodo_classico.png';
import img_metodo_classico_rosato from 'src/assets/images/wine-types/metodo_classico_rosato.png';
import img_metodo_classico_rosso from 'src/assets/images/wine-types/metodo_classico_rosso.png';
import img_rifermentato_bianco from 'src/assets/images/wine-types/rifermentato_bianco.png';
import img_rifermentato_rosato from 'src/assets/images/wine-types/rifermentato_rosato.png';
import img_rifermentato_rosso from 'src/assets/images/wine-types/rifermentato_rosso.png';
import img_rosato from 'src/assets/images/wine-types/rosato.png';
import img_rosso from 'src/assets/images/wine-types/rosso.png';
import img_sidro from 'src/assets/images/wine-types/sidro.png';
import img_type from 'src/assets/images/wine-types/type.png';
import img_coffee from 'src/assets/images/wine-types/coffee.png';
import img_olio from 'src/assets/images/wine-types/olio.png';
// wine glass images import

export const WINE_TYPE_COFFEE = 'coffee';

export const VALID_PRODUCT_WEIGHTS_OPTIONS = [
  { value: 250, label: '250g' },
  { value: 1000, label: '1000g' },
];

export const PRODUCT_VISIBILITY_VALUES_OBJ = {
  active: 'active',
  shown: 'shown',
  new: 'new',
  hide_from_ecommerce_clients: 'hide_from_ecommerce_clients',
};

export const PRODUCER_OPTION_VALUES_OBJ = {
  active: 'active',
  shown: 'shown',
  coffee_producer: 'coffee_producer',
  showcase: 'showcase',
  portfolio: 'portfolio',
};

export const getWineTypeIconLink = (wineType: string) => {
  let wineTypeIcon;
  switch (wineType) {
    case 'bianco':
      wineTypeIcon = img_bianco;
      break;
    case 'birra':
      wineTypeIcon = img_birra;
      break;
    case 'distillati':
      wineTypeIcon = img_distillati;
      break;
    case 'analcolico':
      wineTypeIcon = img_analcolico;
      break;
    case 'macerato':
      wineTypeIcon = img_macerato;
      break;
    case 'metodo_classico':
      wineTypeIcon = img_metodo_classico;
      break;
    case 'metodo_classico_rosato':
      wineTypeIcon = img_metodo_classico_rosato;
      break;
    case 'metodo_classico_rosso':
      wineTypeIcon = img_metodo_classico_rosso;
      break;
    case 'rifermentato_bianco':
      wineTypeIcon = img_rifermentato_bianco;
      break;
    case 'rifermentato_rosato':
      wineTypeIcon = img_rifermentato_rosato;
      break;
    case 'rifermentato_rosso':
      wineTypeIcon = img_rifermentato_rosso;
      break;
    case 'rosato':
      wineTypeIcon = img_rosato;
      break;
    case 'rosso':
      wineTypeIcon = img_rosso;
      break;
    case 'sidro':
      wineTypeIcon = img_sidro;
      break;
    case 'coffee':
      wineTypeIcon = img_coffee;
      break;
    case 'olio':
      wineTypeIcon = img_olio;
      break;
    default:
      wineTypeIcon = img_type;
  }
  return wineTypeIcon;
};

export const CLIENT_TYPE_VALUES_OBJ = {
  active: 'active',
  closed_down: 'closed_down',
  private_client: 'private_client',
  vip: 'vip',
  marketing: 'marketing',
  coffee_only: 'coffee_only',
};

export const CLIENT_ORDER_STATUS_VALUES_OBJ = {
  prepaid: 'prepaid',
  invoiced: 'invoiced',
  delivered: 'delivered',
  paid: 'paid',
  ready_for_delivery: 'ready_for_delivery',
  shipped: 'shipped',
  admin_only: 'admin_only',
  priority: 'priority',
};

export const PRODUCER_ORDER_STATUS_VALUES_OBJ = {
  invoiced: 'invoiced',
  delivered: 'delivered',
  paid: 'paid',
  shipped: 'shipped',
};

export const CLIENT_CATEGORIES = [
  { label: 'Altro', value: 'altro' },
  { label: 'E Commerce', value: 'e_commerce' },
  { label: 'Enoteca', value: 'enoteca' },
  { label: 'Enoteca Con Mescita', value: 'enoteca_con_mescita' },
  { label: 'Privato', value: 'privato' },
  { label: 'Ristorante', value: 'ristorante' },
  { label: 'Enoteca con cucina', value: 'enoteca_con_cucina' },
];

export const CONTAINER_TYPE_OPTIONS = [
  { label: 'Pallet', value: 'pallet' },
  { label: 'Box', value: 'box' },
];

export const RESERVATION_FILTERS_REPLACEMENT_LABEL_KEYS = {
  client_slug: 'client',
  sku: 'product',
  with_expiry_date: 'expiry_date',
  search_text: 'search',
};

export const REFERRAL_FILTER_REPLACEMENT_LABEL_KEYS = {
  month: 'month',
  year: 'year',
  active: 'active',
};

export const PRODUCTS_FILTER_REPLACEMENT_LABEL_KEYS = {
  root_product: 'without_vintage',
  active: 'active',
  client: 'client',
  producer: 'producer',
  listed_in_store: 'available_to_users',
  visible: 'visible',
  novelty: 'novelty',
  hide_from_ecommerce_clients: 'hide_from_e-commerce_customers',
  stock_age: 'stock_age',
  search_text: 'search',
};

export const PRODUCERS_FILTER_REPLACEMENT_LABEL_KEYS = {
  searchText: 'search',
  year: 'year',
  active: 'active',
  month: 'month',
  shown: 'shown',
  showcase: 'in_evidence',
  listedInStore: 'listed',
  portfolio: 'portfolio',
};

export const CLIENT_ORDER_STATUS_TABLE_KEY = {
  submitted: 'forwarded',
  shipped: 'shipped',
  delivered: 'delivered',
  ready_for_delivery: 'ready_for_delivery',
  not_submitted: 'not_submitted',
};

export const CLIENT_ORDER_TABLE_RESULT_REPLACEMENT_LABEL_KEY = {
  searchText: 'search',
  year: 'year',
  month: 'month',
  adminOnly: 'forwarded',
  invoiced: 'sales',
  shipped: 'shipped',
  paid: 'paid',
  reminderEmailSent: 'reminder_sent',
  clientNotified: 'client_notified',
  storageNotified: 'logistics_notified',
  delivered: 'delivered',
  prepaid: 'anticipated',
  clientReferral: 'referrals',
  dateSent: 'date_sent',
  clientRegion: 'region',
  regionProvince: 'province',
};

export const PRODUCER_ORDER_TABLE_RESULT_REPLACEMENT_LABEL_KEY = {
  searchText: 'search',
  year: 'year',
  month: 'month',
  invoiced: 'sales',
  shipped: 'shipped',
  storageNotified: 'warehouse_notified',
  producerNotified: 'manufacturer_notified',
  delivered: 'delivered',
  dateSent: 'date_sent',
};

export const ANNUAL_STATISTICS_TABLE_KEY1 = {
  total_orders: 'total_orders',
  total_bottles: 'total_bottles',
  median_total_bottles: 'median_bottles_per_order',
  average_total_bottles: 'average_bottles_per_order',
  average_products_per_order: 'average_products_per_order',
  total_end_price: 'sales',
  median_total_end_price: 'median_revenue_per_order',
  average_total_end_price: 'average_revenue_per_order',
};

export const ANNUAL_STATISTICS_TABLE_KEY2 = {
  monthly_median_total_orders: 'median_orders_per_month',
  monthly_average_total_orders: 'average_orders_per_month',
  monthly_median_total_bottles: 'median_bottles_per_month',
  monthly_average_total_bottles: 'average_bottles_per_month',
  monthly_median_total_end_price: 'median_monthly_revenue',
  monthly_average_total_end_price: 'average_monthly_revenue',
  monthly_average_margin: 'average_margin',
  average_monthly_margin_percentage: 'average_margin_percentage',
};

export const ANNUAL_STATISTICS_TABLE_KEY3 = {
  yoy_growth_total_orders: 'number_of_orders_per_yoy',
  yoy_growth_total_orders_percentage: 'number_of_orders_per_yoy_percentage',
  yoy_growth_total_bottles: 'number_of_bottles_per_yoy',
  yoy_growth_total_bottles_percentage: 'number_of_bottles_per_yoy_percentage',
  yoy_growth_total_end_price: 'revenue_per_yoy',
  yoy_growth_total_end_price_percentage: 'revenue_per_yoy_percentage',
};

export const AUTH_TYPE = {
  JWT: 'jwt',
  FIREBASE: 'firebase',
};

export const DATE_SENT_VALUES = {
  today: 'today',
  yesterday: 'yesterday',
  this_week: 'this_week',
  last_week: 'last_week',
};

export const DATE_SENT_OPTIONS = [
  {
    label: 'today',
    value: DATE_SENT_VALUES.today,
  },
  {
    label: 'yesterday',
    value: DATE_SENT_VALUES.yesterday,
  },
  {
    label: 'this_week',
    value: DATE_SENT_VALUES.this_week,
  },
  {
    label: 'last_week',
    value: DATE_SENT_VALUES.last_week,
  },
];

export const dateSentFormatter = (dateSent: string) => {
  let startDate = null;
  let endDate = null;

  const currentDate = new Date();

  const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });

  const startOfLastWeek = sub(startOfCurrentWeek, { days: 7 });

  if (dateSent === DATE_SENT_VALUES.today) {
    startDate = startOfToday();
    endDate = endOfToday();
  }

  if (dateSent === DATE_SENT_VALUES.yesterday) {
    startDate = startOfYesterday();
    endDate = endOfYesterday();
  }

  if (dateSent === DATE_SENT_VALUES.this_week) {
    startDate = startOfCurrentWeek;
    endDate = endOfWeek(startOfCurrentWeek);
  }

  if (dateSent === DATE_SENT_VALUES.last_week) {
    startDate = startOfLastWeek;
    endDate = endOfWeek(startOfLastWeek);
  }

  return { startDate, endDate };
};

export const CLIENT_REFERRAL_SOURCE_OPTIONS = [
  {
    label: 'conoscenza_personale',
    value: 'conoscenza_personale',
  },
  {
    label: 'contatto_su_web',
    value: 'contatto_su_web',
  },
  {
    label: 'fiera',
    value: 'fiera',
  },
  {
    label: 'no_referral',
    value: 'no_referral',
  },
  {
    label: 'passaparola',
    value: 'passaparola',
  },
  {
    label: 'produttore',
    value: 'produttore',
  },
  {
    label: 'agent',
    value: 'ambassador',
  },
];

export const PAYMENT_DURATION_OPTIONS = [
  { value: '30 giorni f.m.', label: '30 days_fm' },
  { value: '60 giorni f.m.', label: '60 days_fm' },
  { value: '90 giorni f.m.', label: '90 days_fm' },
  { value: 'anticipato', label: 'anticipated', pdfLabel: 'pdf_anticipated' },
];

export const DEFAULT_PAYMENT_DURATION = PAYMENT_DURATION_OPTIONS[0].value;
export const ANTICIPATED_PAYMENT_DURATION_LABELS = {
  pdfLabel: 'pdf_anticipated',
  label: 'anticipated',
  value: 'anticipato',
};

export const WINE_TYPE_TABLE_RESULT_REPLACEMENT_LABEL_KEY = {
  searchText: 'search',
  year: 'year',
  month: 'month',
  region: 'region',
  province: 'province',
};
