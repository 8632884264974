// ----------------------------------------------------------------------

export const AUTH_STORAGE_KEYS = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  accessTokenLegacy: 'accessTokenLegacy',
  refreshTokenLegacy: 'refreshTokenLegacy',
  user: 'user',
  selectedAgentClientId: 'selectedAgentClientId',
};

export function localStorageAvailable() {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
}

export function localStorageGetItem(key: string, defaultValue = '') {
  const storageAvailable = localStorageAvailable();

  let value;

  if (storageAvailable) {
    value = localStorage.getItem(key) || defaultValue;
  }

  return value;
}

export function localStorageSetItem(key: string, val = '') {
  const storageAvailable = localStorageAvailable();

  if (storageAvailable) {
    localStorage.setItem(key, val);
  }
}

export function localStorageRemoveItem(key: string) {
  const storageAvailable = localStorageAvailable();

  if (storageAvailable) {
    localStorage.removeItem(key);
  }
}
