import { Children } from 'react';
// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/material';

// types
import { IOrderStats, ITableColumns } from 'src/types';
import { paths } from 'src/routes/paths';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

type Props = {
  row: IOrderStats;
  headLabel: Partial<ITableColumns>[];
  isTotalRow?: boolean;
};

export default function CustomTableRow({ row, headLabel, isTotalRow }: Props) {
  return (
    <TableRow hover>
      {Children.toArray(
        headLabel.map((headCell) => {
          if (headCell?.field_mod) {
            return (
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  width: headCell.width,
                  minWidth: headCell.minWidth,
                }}
                align={headCell.align || 'left'}
              >
                {/* @ts-ignore */}
                {row?.[headCell?.field_mod]}
              </TableCell>
            );
          }
          if (headCell?.field) {
            if (headCell.field === 'orderId') {
              return (
                <TableCell
                  sx={{
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                    whiteSpace: 'nowrap',
                    textDecoration: 'none',
                    color: 'black',
                    '&:hover': {
                      textDecoration: !isTotalRow ? 'underline' : 'none',
                    },
                    fontWeight: isTotalRow ? 'bold' : 'normal',
                  }}
                  align={headCell.align || 'left'}
                >
                  <Box
                    component="span"
                    sx={{
                      '& a': {
                        textDecoration: 'none',
                        color: 'black',
                        '&:hover': {
                          textDecoration: !isTotalRow ? 'underline' : 'none',
                        },
                      },
                    }}
                  >
                    {!isTotalRow ? (
                      <Link to={paths.admin.orderTypes.clientOrders.details(row.orderId)}>
                        {/* @ts-ignore */}
                        {row?.[headCell?.field]}
                      </Link>
                    ) : (
                      <>{row?.[headCell?.field]}</>
                    )}
                  </Box>
                </TableCell>
              );
            }
            return (
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  width: headCell.width,
                  minWidth: headCell.minWidth,
                  fontWeight: isTotalRow ? 'bold' : 'normal',
                }}
                align={headCell.align || 'left'}
              >
                {/* @ts-ignore */}
                {row?.[headCell?.field]}
              </TableCell>
            );
          }
          return null;
        }),
      )}
    </TableRow>
  );
}
