import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

import { CheckoutProvider } from 'src/sections/customer/checkout/context';

import CustomerGuard from 'src/auth/guard/customer-guard';
import AdminAgentOrCusotmerGuard from 'src/auth/guard/admin-agent-customer-guard';

import { paths } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));

const CatalogShopPage = lazy(() => import('src/pages/customer/catalog'));
const CheckoutPage = lazy(() => import('src/pages/customer/checkout'));
const PaymentStatusPage = lazy(() => import('src/pages/customer/payment-status'));
const OrderListPage = lazy(() => import('src/pages/customer/orders/order-list'));
const OrderDetailsPage = lazy(() => import('src/pages/customer/orders/details'));
const ProducerListPage = lazy(() => import('src/pages/customer/producer-list'));
// ----------------------------------------------------------------------

export const customerRoutes = [
  {
    element: (
      <AuthGuard>
        <CheckoutProvider>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </CheckoutProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.customer.catalog,
        element: (
          <AdminAgentOrCusotmerGuard>
            <CatalogShopPage />
          </AdminAgentOrCusotmerGuard>
        ),
      },
      {
        path: paths.customer.checkout,
        element: (
          <AdminAgentOrCusotmerGuard>
            <CheckoutPage />
          </AdminAgentOrCusotmerGuard>
        ),
      },
      {
        path: paths.customer.orders.root,
        element: (
          <CustomerGuard>
            <OrderListPage />
          </CustomerGuard>
        ),
      },
      {
        path: paths.customer.orders.details(':order_code'),
        element: (
          <CustomerGuard>
            <OrderDetailsPage />
          </CustomerGuard>
        ),
      },
      {
        path: paths.customer.producers,
        element: (
          <CustomerGuard>
            <ProducerListPage />
          </CustomerGuard>
        ),
      },
    ],
  },
  {
    element: (
      <AuthGuard>
        <CheckoutProvider>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </CheckoutProvider>
      </AuthGuard>
    ),
    children: [{ path: paths.customer.paymentStatus, element: <PaymentStatusPage /> }],
  },
];
