import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

import AdminOrAuditorGuard from 'src/auth/guard/admin-auditor-guard';
import { paths } from '../paths';

// ----------------------------------------------------------------------
const ProviderPage = lazy(() => import('src/pages/admin/producer-order/list'));
const ProducerOrderDetailsPage = lazy(() => import('src/pages/admin/producer-order/details'));
// ----------------------------------------------------------------------
export const auditorRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.admin.orderTypes.producerOrders.root,
        element: (
          <AdminOrAuditorGuard>
            <ProviderPage />
          </AdminOrAuditorGuard>
        ),
      },
      {
        path: paths.admin.orderTypes.producerOrders.details(':order_code'),
        element: (
          <AdminOrAuditorGuard>
            <ProducerOrderDetailsPage />
          </AdminOrAuditorGuard>
        ),
      },
    ],
  },
];
