import axiosInstance, { endpoints } from 'src/utils/axios';

// hooks
import useGetData from 'src/hooks/use-get-data';
// types
import {
  IProductItem,
  ICatalogShopViewFilters,
  IFetchBaseParams,
  APIListResponse,
  IListFilters,
  IProducerItem,
  ICartProductsItem,
  IAddToCart,
  IOrderPrepayment,
  ICurrentCartOrder,
  IConfirmOrder,
  ICustomerOrderItem,
  ICountries,
  IClientOrderDetails,
  IWineTypeItem,
  IClientActiveOverlayData,
  IClientDetailsData,
  IAgentAssociatedClients,
} from 'src/types';
import { isUserAgent } from 'src/utils/check-user-is-admin';

export function useGetProducers(params?: IListFilters<IFetchBaseParams>) {
  return useGetData<IListFilters<IFetchBaseParams>, APIListResponse<IProducerItem[]>>({
    // eslint-disable-next-line no-nested-ternary
    endpoint: isUserAgent()
      ? params?.clientId
        ? endpoints.customer.producers.root
        : null
      : endpoints.customer.producers.root,
    params,
  });
}
// /customer/products/pricing
export function useGetProducts(params?: Partial<ICatalogShopViewFilters>) {
  return useGetData<
    Partial<ICatalogShopViewFilters>,
    APIListResponse<IProductItem[]> & {
      low_price: number;
      high_price: number;
      products: IProductItem[];
    }
  >({
    // eslint-disable-next-line no-nested-ternary
    endpoint: isUserAgent()
      ? params?.client_id
        ? endpoints.customer.products.root
        : null
      : endpoints.customer.products.root,
    params,
  });
}

export function useGetClientOrderList(params?: Partial<IFetchBaseParams>) {
  return useGetData<Partial<IFetchBaseParams>, APIListResponse<ICustomerOrderItem[]>>({
    endpoint: endpoints.customer.client_orders.root,
    params,
  });
}

export function useGetProductsPricing() {
  return useGetData<
    {},
    {
      low_price: number;
      high_price: number;
    }
  >({
    endpoint: endpoints.customer.products.pricing,
  });
}

export function useGetWineTypes(params?: Partial<IFetchBaseParams>) {
  return useGetData<Partial<IFetchBaseParams>, IWineTypeItem[]>({
    endpoint: endpoints.customer.products.wine_types,
    params,
  });
}

export function useGetAgentAssociatedClients(params?: Partial<IFetchBaseParams>) {
  return useGetData<Partial<IFetchBaseParams>, IAgentAssociatedClients[]>({
    endpoint: isUserAgent() ? endpoints.admin.agent_associated_clients : null,
    params,
  });
}

export function useGetGrapeTypes(params?: Partial<IFetchBaseParams>) {
  return useGetData<Partial<IFetchBaseParams>, { grape_types: string[] }>({
    endpoint: endpoints.customer.products.grape_types,
    params,
  });
}

export function useGetCountries(params?: Partial<IFetchBaseParams>) {
  return useGetData<Partial<IFetchBaseParams>, ICountries>({
    // eslint-disable-next-line no-nested-ternary
    endpoint: isUserAgent()
      ? params?.clientId
        ? endpoints.customer.producers.countries
        : null
      : endpoints.customer.producers.countries,
    params,
  });
}

export function useGetCartProducts(params?: Partial<IFetchBaseParams>) {
  return useGetData<Partial<IFetchBaseParams>, ICartProductsItem>({
    // eslint-disable-next-line no-nested-ternary
    endpoint: isUserAgent()
      ? params?.clientId
        ? endpoints.customer.cart_products.root
        : null
      : endpoints.customer.cart_products.root,
    params,
  });
}

export function useGetCurrentOrder(params?: Partial<IFetchBaseParams>) {
  const isCartEmpty = params?.isCartEmpty || false;
  return useGetData<Partial<IFetchBaseParams>, ICurrentCartOrder>({
    endpoint: !isCartEmpty ? endpoints.customer.client_orders.current_order : null,
    params: { clientId: params?.clientId },
  });
}

export function useGetClientOrder({ order_code }: IFetchBaseParams) {
  return useGetData<Partial<IFetchBaseParams>, IClientOrderDetails>({
    endpoint: endpoints.customer.client_orders.details(order_code as string),
  });
}
// /customer/payment-status
// implement an API for payment status updation in client order
export async function updateClientOrderPaymentFields({
  orderId,
  stripePaymentIntentId,
}: {
  orderId: string | null;
  stripePaymentIntentId: string | null;
}) {
  const URL = `${endpoints.customer.payment_status}`;
  await axiosInstance.put(URL, { orderId, stripePaymentIntentId });
}

export async function addToCart(data: IAddToCart) {
  const URL = `${endpoints.customer.cart_products.root}`;
  await axiosInstance.post(URL, data);
}

export async function deleteFromCart(sku: string, clientId?: string | number) {
  const URL = isUserAgent()
    ? `${endpoints.customer.cart_products.root}/${sku}/${clientId}`
    : `${endpoints.customer.cart_products.root}/${sku}`;
  await axiosInstance.delete(URL);
}

export async function updateOrderPrepayment(data: IOrderPrepayment) {
  const URL = `${endpoints.customer.client_orders.current}`;
  await axiosInstance.put(URL, data);
}

export async function updateCurrentOrderAddress(data: Partial<ICurrentCartOrder>) {
  const URL = `${endpoints.customer.client_orders.current}`;
  await axiosInstance.put(URL, data);
}

export async function confirmOrder(payload: IConfirmOrder) {
  const URL = `${endpoints.customer.client_orders.current}`;
  const {
    data: { data: res },
  } = await axiosInstance.put(URL, { ...payload });
  return res;
}
export function useGetClientActiveOverlay() {
  return useGetData<Partial<IFetchBaseParams>, IClientActiveOverlayData>({
    endpoint: endpoints.customer.overlay.active,
  });
}
export function useGetClientFallbackOverlay() {
  return useGetData<Partial<IFetchBaseParams>, IClientActiveOverlayData>({
    endpoint: endpoints.customer.overlay.fallback,
  });
}
export async function MarkOverlaySeen(overlay_id: string) {
  const URL = `${endpoints.customer.overlay.mark_seen}/${overlay_id}/seen`;
  await axiosInstance.post(URL);
}
export function useGetClientDetails() {
  return useGetData<Partial<IFetchBaseParams>, IClientDetailsData>({
    endpoint: endpoints.customer.overlay.client_details,
  });
}

export async function getCustomerOrderPaymentUrl(orderCode: string) {
  const queryString = new URLSearchParams({
    orderCode: orderCode?.toString() ?? '',
  }).toString();
  const URL = `${endpoints.customer.cart_products.checkout}?${queryString}`;
  const res = await axiosInstance.post(URL);
  return res;
}
