import { CONSTANT_VARIABLES } from 'src/constants/constants-variables';
import { getStorage } from 'src/hooks/use-local-storage';

export const isRoleAdmin = (): boolean => {
  const { ADMIN, USER_STORAGE_KEY } = CONSTANT_VARIABLES;

  if (getStorage(USER_STORAGE_KEY)) {
    const { role } = getStorage(USER_STORAGE_KEY);
    return role === ADMIN;
  }
  return false;
};

export const isRoleCustomer = (): boolean => {
  const { CUSTOMER, USER_STORAGE_KEY } = CONSTANT_VARIABLES;

  if (getStorage(USER_STORAGE_KEY)) {
    const { role } = getStorage(USER_STORAGE_KEY);
    return role === CUSTOMER;
  }
  return false;
};

export const isLoggedIn = (): boolean => {
  const { USER_STORAGE_KEY } = CONSTANT_VARIABLES;

  if (getStorage(USER_STORAGE_KEY)) {
    const { email } = getStorage(USER_STORAGE_KEY);

    return email || false;
  }
  return false;
};

export const isUserAgent = (): boolean => {
  const { AGENT, USER_STORAGE_KEY } = CONSTANT_VARIABLES;

  if (getStorage(USER_STORAGE_KEY)) {
    const { role } = getStorage(USER_STORAGE_KEY);
    return role === AGENT;
  }
  return false;
};

export const isUserAuditor = (): boolean => {
  const { AUDITOR, USER_STORAGE_KEY } = CONSTANT_VARIABLES;

  if (getStorage(USER_STORAGE_KEY)) {
    const { role } = getStorage(USER_STORAGE_KEY);
    return role === AUDITOR;
  }
  return false;
};
